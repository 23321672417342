//Fonts
@font-face {
    font-family: AudiTypeScreen;
    src: url(fonts/AudiTypeScreen-Normal.woff) format("woff"),
         url(fonts/AudiTypeScreen-Normal.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: AudiTypeScreen;
    src: url(fonts/AudiTypeScreen-Light.woff) format("woff"),
         url(fonts/AudiTypeScreen-Light.woff2) format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: AudiTypeScreen;
    src: url(fonts/AudiTypeScreen-Bold.woff) format("woff"),
         url(fonts/AudiTypeScreen-Bold.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: AudiTypeExtended;
    src: url(fonts/AudiTypeExtended-Normal.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: AudiTypeExtended;
    src: url(fonts/AudiTypeExtended-Bold.woff) format("woff"),
         url(fonts/AudiTypeExtended-Bold.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
}

//Custom reset
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a{
    color: inherit;
    text-decoration: none;
}

button{
    outline: none;
    background: none;
    border: none;
}

//Main
body, button, select, input{
    font-family: AudiTypeScreen;
}
